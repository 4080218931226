/* eslint-env browser */
import React from "react";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../axiosClient.js";
import DateAndTime from "../../../components/DateAndTime/DateAndTime.jsx";

const pluralize = (isPlural) => {
  return isPlural ? "s" : "";
};

function ElapsedStudyDuration({
  // Props
  daysToUpdate,
  device,
  setError,
}) {
  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const [enrollmentForDevice, setEnrollmentForDevice] = React.useState({});

  React.useEffect(() => {
    const getDeviceQuery = async () => {
      try {
        if (device) {
          const {data} = await axios({
            method: "get",
            url: `/enrollments?tzSerial=${device.tzSerial}`,
          });

          setEnrollmentForDevice(data[0]);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    getDeviceQuery();
  }, [device, setEnrollmentForDevice, setError]);

  const confirmation = React.useMemo(() => {
    //---------------------------------------------------------------------------
    // If there is no enrollment, the last enrollment was already attached to a
    // study, or the current enrollment is empty, don't display anything
    //---------------------------------------------------------------------------
    if (!enrollmentForDevice || !!enrollmentForDevice.studyId || !enrollmentForDevice.deviceEnrollmentId) {
      return null;
    }
    const enrollmentStartTime = DateTime.fromISO(enrollmentForDevice.enrollmentStartTime);
    const timeCompleted = DateTime.utc().diff(enrollmentStartTime);
    const daysCompleted = timeCompleted.isValid ? Math.round(Number(timeCompleted.as("days"))) : "";
    const hoursCompleted = timeCompleted.isValid ? Math.round(Number(timeCompleted.as("hours"))) : "";

    // hours instead of days to avoid potential rounding errors
    const hoursToUpdate = daysToUpdate * 24;

    const updatedEndDate =
      enrollmentStartTime.isValid && !Number.isNaN(hoursToUpdate)
        ? enrollmentStartTime.plus({hours: hoursToUpdate})
        : "";

    const completedString = `${daysCompleted} day${pluralize(daysCompleted > 1)}`;
    const remainingString = `${daysToUpdate - daysCompleted} day${pluralize(
      daysToUpdate - daysCompleted > 1
    )}`;

    // determine what confirmation message to display
    if (hoursCompleted > 24 && !enrollmentForDevice.stopEventReceived) {
      if (hoursToUpdate > hoursCompleted) {
        return (
          <>
            Device has recorded for {completedString} and will continue recording until{" "}
            <DateAndTime datetime={updatedEndDate} /> is reached.
          </>
        );
      }
      return `Device has already recorded for ${completedString} and will end with ${completedString} of data.`;
    }
    if (enrollmentForDevice.stopEventReceived) {
      if (hoursToUpdate > hoursCompleted) {
        return `Device recorded for ${completedString} then ended, it will now resume for an additional ${remainingString}.`;
      }

      return `Device has already finished a recording of ${completedString}, and will have ${completedString} worth of data.`;
    }
    return "";
  }, [daysToUpdate, enrollmentForDevice]);

  return (
    <Box
      sx={{
        pt: 1,
        ml: 1,
        fontSize: 14,
        color: "text.secondary",
      }}
    >
      {confirmation}
    </Box>
  );
}

ElapsedStudyDuration.propTypes = {
  daysToUpdate: PropTypes.number,
  device: PropTypes.shape({
    tzSerial: PropTypes.string.isRequired,
  }),
  setError: PropTypes.func.isRequired,
};

export default ElapsedStudyDuration;
