const studyIndications = [
  "I24.0-Acute coronary thrombosis not resulting in myocardial infarction",
  "I21.9-Acute myocardial infarction, unspecified",
  "R06.03-Acute respiratory distress",
  "G45.3-Amaurosis fugax",
  "I20.1-Angina pectoris with documented spasm",
  "R06.81-Apnea, not elsewhere classified",
  "I25.112-Atherosclerosic heart disease of native coronary artery with refractory angina pectoris",
  "I25.721-Atherosclerosis of autologous artery coronary artery bypass graft(s) with angina pectoris with documented spasm",
  "I25.728-Atherosclerosis of autologous artery coronary artery bypass graft(s) with other forms of angina pectoris",
  "I25.722-Atherosclerosis of autologous artery coronary artery bypass graft(s) with refractory angina pectoris",
  "I25.720-Atherosclerosis of autologous artery coronary artery bypass graft(s) with unstable angina pectoris",
  "I25.711-Atherosclerosis of autologous vein coronary artery bypass graft(s) with angina pectoris with documented spasm",
  "I25.718-Atherosclerosis of autologous vein coronary artery bypass graft(s) with other forms of angina pectoris",
  "I25.712-Atherosclerosis of autologous vein coronary artery bypass graft(s) with refractory angina pectoris",
  "I25.710-Atherosclerosis of autologous vein coronary artery bypass graft(s) with unstable angina pectoris",
  "I25.761-Atherosclerosis of bypass graft of coronary artery of transplanted heart with angina pectoris with documented spasm",
  "I25.768-Atherosclerosis of bypass graft of coronary artery of transplanted heart with other forms of angina pectoris",
  "I25.762-Atherosclerosis of bypass graft of coronary artery of transplanted heart with refractory angina pectoris",
  "I25.760-Atherosclerosis of bypass graft of coronary artery of transplanted heart with unstable angina",
  "I25.701-Atherosclerosis of coronary artery bypass graft(s), unspecified, with angina pectoris with documented spasm",
  "I25.708-Atherosclerosis of coronary artery bypass graft(s), unspecified, with other forms of angina pectoris",
  "I25.702-Atherosclerosis of coronary artery bypass graft(s), unspecified, with refractory angina pectoris",
  "I25.700-Atherosclerosis of coronary artery bypass graft(s), unspecified, with unstable angina pectoris",
  "I25.751-Atherosclerosis of native coronary artery of transplanted heart with angina pectoris with documented spasm",
  "I25.758-Atherosclerosis of native coronary artery of transplanted heart with other forms of angina pectoris",
  "I25.752-Atherosclerosis of native coronary artery of transplanted heart with refractory angina pectoris",
  "I25.750-Atherosclerosis of native coronary artery of transplanted heart with unstable angina",
  "I25.731-Atherosclerosis of nonautologous biological coronary artery bypass graft(s) with angina pectoris with documented spasm",
  "I25.738-Atherosclerosis of nonautologous biological coronary artery bypass graft(s) with other forms of angina pectoris",
  "I25.732-Atherosclerosis of nonautologous biological coronary artery bypass graft(s) with refractory angina pectoris",
  "I25.730-Atherosclerosis of nonautologous biological coronary artery bypass graft(s) with unstable angina pectoris",
  "I25.791-Atherosclerosis of other coronary artery bypass graft(s) with angina pectoris with documented spasm",
  "I25.798-Atherosclerosis of other coronary artery bypass graft(s) with other forms of angina pectoris",
  "I25.792-Atherosclerosis of other coronary artery bypass graft(s) with refractory angina pectoris",
  "I25.790-Atherosclerosis of other coronary artery bypass graft(s) with unstable angina pectoris",
  "I25.111-Atherosclerotic heart disease of native coronary artery with angina pectoris with documented spasm",
  "I25.118-Atherosclerotic heart disease of native coronary artery with other forms of angina pectoris",
  "I25.110-Atherosclerotic heart disease of native coronary artery with unstable angina pectoris",
  "I49.1-Atrial premature depolarization",
  "I44.2-Atrioventricular block, complete",
  "I44.0-Atrioventricular block, first degree",
  "I44.1-Atrioventricular block, second degree",
  "I48.4-Atypical atrial flutter",
  "I45.2-Bifascicular block",
  "R00.1-Bradycardia, unspecified",
  "G45.1-Carotid artery syndrome (hemispheric)",
  "I63.12-Cerebral infarction due to embolism of basilar artery",
  "I63.423-Cerebral infarction due to embolism of bilateral anterior cerebral arteries",
  "I63.133-Cerebral infarction due to embolism of bilateral carotid arteries",
  "I63.443-Cerebral infarction due to embolism of bilateral cerebellar arteries",
  "I63.413-Cerebral infarction due to embolism of bilateral middle cerebral arteries",
  "I63.433-Cerebral infarction due to embolism of bilateral posterior cerebral arteries",
  "I63.113-Cerebral infarction due to embolism of bilateral vertebral arteries",
  "I63.422-Cerebral infarction due to embolism of left anterior cerebral artery",
  "I63.132-Cerebral infarction due to embolism of left carotid artery",
  "I63.442-Cerebral infarction due to embolism of left cerebellar artery",
  "I63.412-Cerebral infarction due to embolism of left middle cerebral artery",
  "I63.432-Cerebral infarction due to embolism of left posterior cerebral artery",
  "I63.112-Cerebral infarction due to embolism of left vertebral artery",
  "I63.49-Cerebral infarction due to embolism of other cerebral artery",
  "I63.19-Cerebral infarction due to embolism of other precerebral artery",
  "I63.421-Cerebral infarction due to embolism of right anterior cerebral artery",
  "I63.131-Cerebral infarction due to embolism of right carotid artery",
  "I63.441-Cerebral infarction due to embolism of right cerebellar artery",
  "I63.411-Cerebral infarction due to embolism of right middle cerebral artery",
  "I63.431-Cerebral infarction due to embolism of right posterior cerebral artery",
  "I63.111-Cerebral infarction due to embolism of right vertebral artery",
  "I63.429-Cerebral infarction due to embolism of unspecified anterior cerebral artery",
  "I63.139-Cerebral infarction due to embolism of unspecified carotid artery",
  "I63.449-Cerebral infarction due to embolism of unspecified cerebellar artery",
  "I63.40-Cerebral infarction due to embolism of unspecified cerebral artery",
  "I63.419-Cerebral infarction due to embolism of unspecified middle cerebral artery",
  "I63.439-Cerebral infarction due to embolism of unspecified posterior cerebral artery",
  "I63.10-Cerebral infarction due to embolism of unspecified precerebral artery",
  "I63.119-Cerebral infarction due to embolism of unspecified vertebral artery",
  "R07.9-Chest pain, unspecified",
  "I48.20-Chronic atrial fibrillation, unspecified",
  "R42-Dizziness and giddiness",
  "I24.1-Dressler's syndrome",
  "R06.4-Hyperventilation",
  "R07.82-Intercostal pain",
  "I49.2-Junctional premature depolarization",
  "I44.4-Left anterior fascicular block",
  "I44.7-Left bundle-branch block, unspecified",
  "I44.5-Left posterior fascicular block",
  "I45.81-Long QT syndrome",
  "Z79.891-Long term (current) use of opiate analgesic",
  "I48.11-Longstanding persistent atrial fibrillation",
  "Z79.85-Long-term (current) use of injectable non-insulin antidiabetic drugs",
  "G45.2-Multiple and bilateral precerebral artery syndromes",
  "I21.A1-Myocardial infarction type 2",
  "I45.4-Nonspecific intraventricular block",
  "I21.4-Non-ST elevation (NSTEMI) myocardial infarction",
  "I25.2-Old myocardial infarction",
  "R06.01-Orthopnea",
  "R06.89-Other abnormalities of breathing",
  "I44.39-Other atrioventricular block",
  "R07.89-Other chest pain",
  "I44.69-Other fascicular block",
  "I24.8-Other forms of acute ischemic heart disease",
  "I20.8-Other forms of angina pectoris",
  "R06.09-Other forms of dyspnea",
  "Z79.899-Other long term (current) drug therapy",
  "I21.A9-Other myocardial infarction type",
  "I48.19-Other persistent atrial fibrillation",
  "I45.19-Other right bundle-branch block",
  "I49.8-Other specified cardiac arrhythmias",
  "I45.89-Other specified conduction disorders",
  "I45.5-Other specified heart block",
  "G45.8-Other transient cerebral ischemic attacks and related syndromes",
  "I47.29-Other ventricular tachycardia",
  "R00.2-Palpitations",
  "I48.0-Paroxysmal atrial fibrillation",
  "I47.9-Paroxysmal tachycardia, unspecified",
  "R06.3-Periodic breathing",
  "I48.21-Permanent atrial fibrillation",
  "Z86.73-Personal history of transient ischemic attack (TIA), and cerebral infarction without residual deficits",
  "R07.2-Precordial pain",
  "I45.6-Pre-excitation syndrome",
  "I47.0-Re-entry ventricular arrhythmia",
  "I45.0-Right fascicular block",
  "R06.02-Shortness of breath",
  "I49.5-Sick sinus syndrome",
  "R06.83-Snoring",
  "I21.02-ST elevation (STEMI) myocardial infarction involving left anterior descending coronary artery",
  "I21.21-ST elevation (STEMI) myocardial infarction involving left circumflex coronary artery",
  "I21.01-ST elevation (STEMI) myocardial infarction involving left main coronary artery",
  "I21.09-ST elevation (STEMI) myocardial infarction involving other coronary artery of anterior wall",
  "I21.19-ST elevation (STEMI) myocardial infarction involving other coronary artery of inferior wall",
  "I21.29-ST elevation (STEMI) myocardial infarction involving other sites",
  "I21.11-ST elevation (STEMI) myocardial infarction involving right coronary artery",
  "I22.2-Subsequent non-ST elevation (NSTEMI) myocardial infarction",
  "I22.0-Subsequent ST elevation (STEMI) myocardial infarction of anterior wall",
  "I22.1-Subsequent ST elevation (STEMI) myocardial infarction of inferior wall",
  "I22.8-Subsequent ST elevation (STEMI) myocardial infarction of other sites",
  "I47.1-Supraventricular tachycardia",
  "R55-Syncope and collapse",
  "R00.0-Tachycardia; unspecified",
  "R06.82-Tachypnea, not elsewhere classified",
  "R40.4-Transient alteration of awareness",
  "G45.9-Transient cerebral ischemic attack, unspecified",
  "G45.4-Transient global amnesia",
  "R29.5-Transient paralysis",
  "I45.3-Trifascicular block",
  "I48.3-Typical atrial flutter",
  "I48.91-Unspecified atrial fibrillation",
  "I48.92-Unspecified atrial flutter",
  "I20.0-Unstable angina",
  "I49.01-Ventricular fibrillation",
  "I49.02-Ventricular flutter",
  "I49.3-Ventricular premature depolarization",
  "I47.20-Ventricular tachycardia, unspecified",
  "G45.0-Vertebro-basilar artery syndrome",
  "R06.2-Wheezing",
];

export default studyIndications;
