/* eslint-disable complexity */
/* eslint-env browser */
import React from "react";
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Smartphone from "@mui/icons-material/Smartphone";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useEnvironmentVariables from "../../../components/hooks/useEnvironmentVariables.jsx";
import useJwt from "../../../components/hooks/useJwt.jsx";
import useStudyTypeNames from "../../../components/hooks/useStudyTypeNames.jsx";
import Alert from "../../../shared/react/Alert.jsx";
import AutocompleteInput from "../../../shared/react/AutocompleteInput.jsx";
import FormStringInput from "../../../shared/react/FormStringInput.jsx";
import IconWithText from "../../../shared/react/IconWithText.jsx";
import EditableStudyInfo from "../EditableStudyInfo.jsx";
import ElapsedStudyDuration from "./ElapsedStudyDuration.jsx";
import StudyIndications from "./StudyIndications.js";

const studyIndicationOptions = StudyIndications.map((indication) => ({id: indication, name: indication}));

function StudyInfo({
  // Props
  device,
  devices,
  allowedStudyTypes,
  deviceConfigurations,
  arrhythmiaSettings,
  physicianUsers,
  setError,
  eSignEnabled,
  facility,
  requiredFields,
  followUp = false,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const {features} = useEnvironmentVariables();
  const {isInAnyRole} = useJwt();

  //---------------------------------------------------------------------------
  // Retrieve all hook methods from the controlled form
  //---------------------------------------------------------------------------
  const {control, resetField, watch} = useFormContext();
  const watchAllFields = watch();

  //---------------------------------------------------------------------------
  // Filter devices state management
  //---------------------------------------------------------------------------
  const filteredDevices = React.useMemo(() => {
    if (followUp) {
      return [];
    }

    resetField("device", {defaultValue: null});

    // Filter devices based on the current study type selected
    if (["holter", "extendedHolter"].includes(watchAllFields.studyType)) {
      return devices.filter((d) => !d.deviceVariant.capableOfWirelessStudies);
    }
    return devices.filter((d) => d.deviceVariant.capableOfWirelessStudies);
  }, [followUp, resetField, watchAllFields.studyType, devices]);

  //---------------------------------------------------------------------------
  // Determine available study types
  //---------------------------------------------------------------------------
  const determineAllowedStudyTypes = React.useCallback(
    (studyType) => {
      if (!features.configureAllowedStudyTypes) {
        return true;
      }

      if (Object.values(allowedStudyTypes).length === 0) {
        return true;
      }

      return allowedStudyTypes[studyType];
    },
    [allowedStudyTypes, features.configureAllowedStudyTypes]
  );
  const supportedStudyTypes = useStudyTypeNames("complex");
  const studyTypeOptions = React.useMemo(() => {
    // Every study type must be allowed by the facility config and match the type of device selected
    return Object.entries(supportedStudyTypes)
      .filter(([id]) => determineAllowedStudyTypes(id))
      .filter(([id]) => !device || !["holter", "extendedHolter"].includes(id))
      .filter(([id]) => !followUp || ["mct", "mctWithFullDisclosure", "cem"].includes(id))
      .map(([id, name]) => ({id, name: name.long || name}));
  }, [supportedStudyTypes, determineAllowedStudyTypes, device, followUp]);

  const getDeviceSerial = React.useCallback((option) => option.tzSerial, []);
  const getStudyIndicationName = React.useCallback((option) => option.name || option, []);

  return (
    <Grid container columnSpacing={4} rowSpacing={2}>
      {facility?.inboxContract === "evaluation" && (
        <Grid size={12}>
          <Alert level="warning" message={`${facility.name} is currently in an evaluation period.`} />
        </Grid>
      )}
      {/* Study Type */}
      <Grid size={{xs: 12, md: 6}}>
        <FormStringInput
          control={control}
          defaultValue=""
          label={followUp ? "Follow-Up Study Type" : "Study Type"}
          name={followUp ? "followUpStudyType" : "studyType"}
          id={followUp ? "followUpStudyTypeSelect" : "studyTypeSelect"}
          options={studyTypeOptions}
          rules={{required: "Study type is required"}}
        />
      </Grid>

      {!followUp && (
        <>
          {/* Device Selection */}
          <Grid size={{xs: 12, md: 6}} sx={{...(device && {display: "flex", alignItems: "center"})}}>
            {!device && watchAllFields.studyType && (
              <AutocompleteInput
                name="device"
                control={control}
                defaultValue={null}
                id="deviceSelect"
                options={filteredDevices}
                label="Select a Device"
                rules={{required: "Device is required"}}
                variant="single"
                getOptionLabel={getDeviceSerial}
                otherProps={{noOptionsText: "No matching devices found"}}
              />
            )}
            {device && (
              <div data-cy="device-from-notification">
                <IconWithText icon={<Smartphone />} text={device?.tzSerial} />
              </div>
            )}
          </Grid>

          {/* Device Configuration */}
          {!["holter", "extendedHolter"].includes(watchAllFields.studyType) && (
            <Grid size={{xs: 12, md: 6}}>
              <FormStringInput
                control={control}
                defaultValue=""
                label="Device Configuration"
                name="deviceConfig"
                id="deviceConfigurationSelect"
                options={deviceConfigurations}
                rules={{required: "Device configuration is required"}}
              />
            </Grid>
          )}
        </>
      )}

      {/* Study Duration */}
      {watchAllFields.studyType !== "cardiacRehab" && (
        <Grid size={{xs: 12, md: 6}}>
          <FormStringInput
            control={control}
            defaultValue=""
            label={`${followUp ? "Follow-Up Study Duration" : "Study Duration"} (1-30 days)`}
            name={followUp ? "followUpStudyDays" : "studyDays"}
            id={followUp ? "followUpStudyDurationInput" : "studyDurationInput"}
            type="number"
            htmlInputProps={{min: 1, max: 30}}
            rules={{
              required: "Study duration is required",
              min: {
                value: 1,
                message: "Study duration must be greater than or equal to 1",
              },
              max: {
                value: 30,
                message: "Study duration must be less than or equal to 30",
              },
              validate: {
                integer: (value) => Number.isInteger(Number(value)) || "Study duration must be an integer",
                followUp: (value) => {
                  if (!followUp) {
                    return true;
                  }
                  return (
                    Number(watchAllFields.studyDays) + Number(value) <= 30 ||
                    "The total study duration of both studies must be less than or equal to 30"
                  );
                },
              },
            }}
          />
          {!["holter", "extendedHolter"].includes(watchAllFields.studyType) && (
            <ElapsedStudyDuration
              device={device || watchAllFields.device}
              daysToUpdate={Number(watchAllFields.studyDays)}
              setError={setError}
            />
          )}
        </Grid>
      )}

      {!followUp && (
        <>
          {/* Arrhythmia Setting */}
          {watchAllFields.studyType && !watchAllFields.studyType?.toLowerCase().endsWith("holter") && (
            <Grid size={{xs: 12, md: 6}}>
              <FormStringInput
                control={control}
                defaultValue=""
                label="Arrhythmia Setting"
                name="arrhythmiaSetting"
                id="arrhythmiaSettingSelect"
                options={arrhythmiaSettings}
                rules={{required: "Arrhythmia setting is required"}}
              />
            </Grid>
          )}

          {/* Time Zone and Order Number */}
          <EditableStudyInfo requiredFields={requiredFields} />
        </>
      )}

      {/* Study Indication */}
      <Grid size={12}>
        <AutocompleteInput
          name={followUp ? "followUpStudyIndication" : "studyIndication"}
          control={control}
          defaultValue={[]}
          id={followUp ? "followUpStudyIndicationsSelect" : "studyIndicationsSelect"}
          options={studyIndicationOptions}
          label={followUp ? "Follow-Up Study Indications" : "Study Indications"}
          otherProps={{freeSolo: true}}
          rules={{required: "Study Indication is required"}}
          getOptionLabel={getStudyIndicationName}
        />
      </Grid>

      {/* Study Notes */}
      {!followUp && (
        <Grid size={12}>
          <FormStringInput
            control={control}
            defaultValue=""
            label="Study Notes"
            name="studyNotes"
            id="studyNotesInput"
            otherProps={{multiline: true, maxRows: 4}}
            required={false}
          />
        </Grid>
      )}

      {/* Assigned Physicians */}
      {!isInAnyRole(["physician"]) && (
        <Grid size={12}>
          <AutocompleteInput
            name={followUp ? "followUpPhysicianUsers" : "physicianUsers"}
            control={control}
            defaultValue={[]}
            id={followUp ? "followUpPhysicianUsersSelect" : "physicianUsersSelect"}
            options={physicianUsers}
            label={followUp ? "Assign Follow-Up Reading Physicians" : "Assign Reading Physicians"}
            required={eSignEnabled && physicianUsers.length > 0}
            rules={{
              ...(eSignEnabled &&
                physicianUsers.length > 0 && {
                  required: "Study must be assigned to at least one physician user",
                }),
            }}
            getOptionLabel={(option) => option.fullName}
            otherProps={{
              noOptionsText:
                physicianUsers.length > 0 ? (
                  "No matching physicians found"
                ) : (
                  <Box style={{display: "flex", alignItems: "center"}}>
                    <Alert level="warning" message="There are no physicians to assign to this study" />
                  </Box>
                ),
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

StudyInfo.propTypes = {
  device: PropTypes.object,
  devices: PropTypes.array.isRequired,
  allowedStudyTypes: PropTypes.object.isRequired,
  deviceConfigurations: PropTypes.array.isRequired,
  arrhythmiaSettings: PropTypes.array.isRequired,
  physicianUsers: PropTypes.array.isRequired,
  setError: PropTypes.func.isRequired,
  eSignEnabled: PropTypes.bool.isRequired,
  facility: PropTypes.object,
  requiredFields: PropTypes.object,
  followUp: PropTypes.bool,
};

export default StudyInfo;
