/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import AddProviders from "../../../shared/react/AddProviders.jsx";
import NewStudyDialog from "./NewStudyDialog.jsx";

function NewStudyWrapper({
  // Props
  device,
}) {
  //---------------------------------------------------------------------------
  // NOTE: The only reason that this file is separate from NewStudyDialog.jsx is because of Angular.
  //  Once we switch completely from Angular to React, ConfirmProvider can be moved to an app.js so
  //  that it encapsulates the entire application. Then these two files can be combined.
  //---------------------------------------------------------------------------
  return (
    <AddProviders>
      <NewStudyDialog device={device} />
    </AddProviders>
  );
}

NewStudyWrapper.propTypes = {
  device: PropTypes.object,
};

export default NewStudyWrapper;
