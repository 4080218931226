/* eslint-env browser */
import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Autocomplete from "@mui/material/Autocomplete";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import {debounce} from "@mui/material/utils";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../axiosClient.js";

function PhysicianAutocompleteInput({
  // Props
  name,
  label,
  defaultValue,
  rules,
  required = false,
  "data-cy": dataCy,
}) {
  //---------------------------------------------------------------------------
  // Retrieve all hook methods from the controlled form
  //---------------------------------------------------------------------------
  const {control, setValue, getValues} = useFormContext();

  //---------------------------------------------------------------------------
  // Control the input value and options for this Autocomplete fields
  //---------------------------------------------------------------------------
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const fetchPhysicians = React.useCallback(
    async (input) => {
      try {
        // https://clinicaltables.nlm.nih.gov/apidoc/npi_org/v3/doc.html
        const {data} = await axios({
          method: "get",
          url: "https://clinicaltables.nlm.nih.gov/api/npi_idv/v3/search",
          params: {
            terms: input,
            maxList: 100,
            ef: "addr_practice.phone:phone",

            // If this is the NPI input, search on just the NPI field
            ...(name === "physicianNpiNumber" && {sf: "NPI"}),
          },
        });

        /**
         * Response is returned in the following format:
         * [
         *    <number of results>,
         *    <array of NPI numbers for returned items>,
         *    <hash of extra data requested (null in our use case here)>,
         *    <array of returned items in format specified>,
         *    <array of returned items in the code system specified>,
         * ]
         *
         * In our use case, returned items are in the following format:
         * [
         *    name.full,
         *    NPI,
         *    provider_type,
         *    addr_practice.full
         * ]
         */
        return data[3].map((item, index) => {
          const physicianData = {
            name: item[0],
            npi: item[1],
            type: item[2],
            address: item[3],
          };

          if (data[2] !== null && data[2]?.phone) {
            physicianData.phone = data[2]?.phone[index];
          }

          return physicianData;
        });
      } catch (err) {
        console.error(err);
        return [];
      }
    },
    [name]
  );

  const fetch = React.useMemo(
    () =>
      debounce((input) => {
        const getData = fetchPhysicians(input);
        getData.then((data) => {
          setOptions(data);
          return data;
        });
      }, 400),
    [fetchPhysicians]
  );

  React.useEffect(() => {
    // active variable helps prevent race conditions
    let active = true;
    const value = getValues(name);

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(inputValue, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [getValues, name, inputValue, fetch]);

  const getOptionLabel = React.useCallback(
    (option) => {
      if (typeof option === "string") {
        return option;
      }

      if (name === "physicianName") {
        return option.name;
      }

      return option.npi;
    },
    [name]
  );

  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister
      rules={rules}
      defaultValue={defaultValue}
      render={({field: {onChange, value}, fieldState: {error}}) => (
        <Autocomplete
          id={name}
          getOptionLabel={getOptionLabel}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          freeSolo
          autoSelect
          value={value}
          onChange={(event, newValue) => {
            // If an option was selected, autofill inputs
            if (newValue && typeof newValue !== "string") {
              if (name === "physicianName") {
                // If this is the name input, autofill NPI
                setValue("physicianNpiNumber", newValue.npi);
              } else {
                // Otherwise this is the NPI input, so autofill name
                setValue("physicianName", newValue.name);
              }
              setValue("physicianType", newValue.type);
              setValue("physicianAddress", newValue.address);
              setValue("physicianPhoneNumber", newValue.phone);
            }

            onChange(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required={required}
              error={!!error}
              helperText={error ? error.message : null}
              variant="standard"
              label={label}
              fullWidth
              data-cy={dataCy}
            />
          )}
          renderOption={(props, option) => {
            return (
              <ListItem {...props} key={option.npi} data-cy={`${dataCy}-option-${option.npi}`}>
                <ListItemText
                  primary={`${option.name} - ${option.npi}`}
                  secondary={
                    <>
                      {option.type}
                      <br />
                      {option.address}
                    </>
                  }
                />
              </ListItem>
            );
          }}
          isOptionEqualToValue={(option, val) =>
            !options?.length || val === undefined || val === "" || (option.npi || option) === (val.npi || val)
          }
        />
      )}
    />
  );
}

PhysicianAutocompleteInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  rules: PropTypes.object,
  required: PropTypes.bool,
  "data-cy": PropTypes.string,
};

export default PhysicianAutocompleteInput;
