/* eslint-env browser */
import React from "react";
import {useFormContext} from "react-hook-form";
import {DateTime, IANAZone} from "luxon";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid2";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useEnvironmentVariables from "../../components/hooks/useEnvironmentVariables.jsx";
import AutocompleteInput from "../../shared/react/AutocompleteInput.jsx";
import FormStringInput from "../../shared/react/FormStringInput.jsx";
import timeZoneNamesFallback from "./NewStudy/timeZoneNamesFallback.js";

// We need a fallback in case Intl.supportedValuesOf() isn't supported
const timeZoneNames =
  typeof Intl.supportedValuesOf === "function" ? Intl.supportedValuesOf("timeZone") : timeZoneNamesFallback;

const getFormattedTimeZoneObject = (timeZone) => {
  const dateTimeObject = timeZone ? DateTime.local().setZone(timeZone) : DateTime.local();
  return {
    id: dateTimeObject.toFormat("z"),
    name: dateTimeObject.toFormat("z - ZZZZ"),
    offset: dateTimeObject.toFormat("ZZZ"),
  };
};

const timeZoneOptions = timeZoneNames
  .filter((zone) => IANAZone.isValidZone(zone))
  .map(getFormattedTimeZoneObject)
  .sort((a, b) => Number(a.offset) - Number(b.offset));

function EditableStudyInfo({
  // Props
  study = {},
  requiredFields,
}) {
  //---------------------------------------------------------------------------
  // Global Variables
  //---------------------------------------------------------------------------
  const {features} = useEnvironmentVariables();

  //---------------------------------------------------------------------------
  // Retrieve all hook methods from the controlled form
  //---------------------------------------------------------------------------
  const {control} = useFormContext();

  const timeZoneDefault = React.useMemo(() => getFormattedTimeZoneObject(study.timeZone), [study.timeZone]);

  return (
    <>
      {/* Time Zone */}
      {features.studyTimeZone && (
        <Grid size={{xs: 12, md: 6}}>
          <AutocompleteInput
            control={control}
            defaultValue={timeZoneDefault}
            label="Time Zone"
            name="timeZone"
            id="timeZoneSelect"
            options={timeZoneOptions}
            variant="single"
            required
            rules={{required: "Time zone is required"}}
          />
        </Grid>
      )}

      {/* Order Number */}
      <Grid size={6}>
        <FormStringInput
          required={requiredFields?.orderNumber}
          control={control}
          defaultValue={study.orderNumber || ""}
          label="Order Number"
          name="orderNumber"
          id="orderNumberInput"
          rules={{...(requiredFields?.orderNumber && {required: "Order Number is required"})}}
        />
      </Grid>
    </>
  );
}

EditableStudyInfo.propTypes = {
  study: PropTypes.object,
  requiredFields: PropTypes.object.isRequired,
};

export default EditableStudyInfo;
